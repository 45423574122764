import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Mobile from "./components/Header/mobile";
import Morenews from "./components/MoreNews/Morenews";
import Singlenews from "./components/SingleNews/Singlenews";
import SingleEvent from "./components/SingleEvent/Singleevent";
import SingleLaws from "./components/singlelaws/singlelaws";
import Signup from "./components/signup/signup";
import Profile from "./components/Profile/profile";
import Cases from "./components/ReportedCases/Cases";
import SingleCase from "./components/SingleCase/SingleCase";
import MoreEvents from "./components/MoreEvents/Events";
import ReportCase from "./components/ReportCase/ReportCase";
import Mision from "./components/mision/mision";
import Laws from "./components/Laws/Laws";
import LawsInternacionality from "./components/Laws/lawsInternacional";
import LawsNationality from "./components/Laws/LawsNationality";
import Protection from "./components/protection_against_discrimination/protection";
import Constitution from "./components/Constitution/Constitution";
import AboutUsLaw from "./components/Aboutus/aboutusLaw";
import Manuals from "./components/Manuals/Manuals";
import Error404 from "./components/404/Error404";
import Partnert from "./components/partneret/partner";
import ScrollToTop from "./components/ScrollTop";
import Privacy from "./components/privacy/privacy";
import Terms from "./components/Terms/Terms";
import DiversityAndEquality from "./components/DiversityAndEquality/DiversityAndEquality";
import Share from "./components/ShareButtons/share";
import SafetyOfChildren from "./components/safetyOfChildren/safetyOfChildren";

function App(key) {
  return (
    <>
      <div className="">
        <Router>
          <ScrollToTop />
          <Header />

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/news" element={<Morenews />} />
            <Route
              exact
              path="/LawsInternacionality"
              element={<LawsInternacionality />}
            />
            <Route
              exact
              path="/LawsNationality"
              element={<LawsNationality />}
            />
            <Route exact path="/laws" element={<Laws />} />
            <Route exact path="/Mision" element={<Mision />} />
            <Route exact path="/news" element={<Morenews />} />
            <Route exact path="/Constitution" element={<Constitution />} />
            <Route exact path="/protection" element={<Protection />} />
            <Route exact path="/news/:id" element={<Singlenews />} />
            <Route exact path="/events" element={<MoreEvents />} />
            <Route exact path="/event/:id" element={<SingleEvent />} />
            <Route exact path="/singlelaws/:id" element={<SingleLaws />} />
            {/* <Route exact path="/login" element={<Login />} /> */}
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/cases" element={<Cases />} />
            <Route exact path="/report" element={<ReportCase />} />
            <Route path="*" exact={true} element={<Error404 />} />
            <Route exact path="/profile/:id" element={<Profile />} />
            <Route exact path="/chat/:id" element={<SingleCase />} />
            <Route exact path="/AboutUs" element={<AboutUsLaw />} />
            <Route exact path="/Terms" element={<Terms />} />
            <Route exact path="/safetyOfChildren" element={<SafetyOfChildren />} />
            <Route exact path="/manuals" element={<Manuals />} />
            <Route exact path="/partnert" element={<Partnert />} />
            <Route exact path="/Privacy" element={<Privacy />} />
            <Route exact path="/DiversityAndEquality" element={<DiversityAndEquality />} />
          </Routes>
          <Share />
          <Footer />
        </Router>

      </div>
    </>
  );
}

export default App;
