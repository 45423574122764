import React, { useEffect, useState } from "react";
import "../../App.css";
import axios from "../../axios";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../loader/loader";
import { Link } from "react-router-dom";

const TermsOfUse = (props) => {
  const [termsOfUse, settermsOfUse] = useState([]);
  const [iSloading, setLoading] = useState(true);

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") return diqka?.content_al;
    if (langId === "sr") return diqka?.content_sr;
    if (langId === "en") return diqka?.content_en;
  };

  useEffect(() => {
    axios
      .get("/general/terms_conditions")
      .then((res) => {
        settermsOfUse(res.data);
        setLoading(false);
      })
      .catch((err) => console.error("Error:", err));
  }, []);


  return (
    <>
      {iSloading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="linkpageAbout">
            <div className="col-12">
              <h1 className="text-center">
                {translateMe("terms", props?.landId)}
              </h1>
              {termsOfUse?.map((e) => {
                const content =
                  props?.landId === "al"
                    ? e.content_al
                    : props?.landId === "en"
                    ? e.content_en
                    : e.content_sr;

                return (
                  <div key={e.id} className="aboutUsLaw">
                    <p
                      dangerouslySetInnerHTML={{ __html: content }}
                    ></p>
                 
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  landId: state.data.langId,
  langData: state.data.langData,
});

export default connect(mapStateToProps)(TermsOfUse);
