import React from "react";
import "./Footer.scss";
import Logo from "../images/Group 80.png";
import app from "./images/google.png";
import android from "./images/app.png";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

const Footer = (props) => {
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  const funkcioniIm = () => {
    setTimeout(() => {

      window.scrollTo(0, 3200);
    }, 500);
    // window.pageYOffset === 500;


  }
  return (
    <>
      <div className="banner_footer">
        <div className="container text-footer-mobile">
          <div className="row text-white">
            <div className="col-lg-4 col-md-4 col-12 mb-5">
              <img src={Logo} alt="" />
            </div>
            <div className="col-lg-4 col-md-4  col-12">
              {/* <h5>{translateMe("topMenuContact", props?.landId)}</h5> */}
              <div className="row">
                <div className="col-lg-3 col-md-5 col-5 ">
                  {/* <p className="">{translateMe("address", props?.landId)}</p> */}
                  <p className="">{translateMe("address", props?.landId)}</p>
                  {/* <p className="emailfooter" >Email</p>
                  <p className="pt-4">{translateMe("phone", props?.landId)}</p> */}
                </div>
                <div className="col-lg-9 col-md-7 col-7 mb-5 text-start ">
                  <span className="">{translateMe("addressGovernment", props?.landId)}</span><br />
                  <span className="">{translateMe("addressOffice", props?.landId)}</span><br />
                  <span className="">{translateMe("addressFloor", props?.landId)}</span><br />
                  <span className="">{translateMe("addressSquare", props?.landId)}</span><br />
                  <span className="mb-2">{translateMe("addressCity", props?.landId)}</span><br />
                  {/* <p className="pt-2">
                    {" "}
                    {translateMe("emailFoot", props?.landId)}
                  </p>
                  <p className="pt-3 ">
                    {translateMe("mobilenumber", props?.landId)}
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2  col-12 aboutcompany mb-5">
              {/* <h5 className="">{translateMe("company", props?.landId)}</h5> */}
              <Link to="/Terms" className="terms">
                {translateMe("terms", props?.landId)}
              </Link>

              <Link to="/Privacy" className="policy">
                {translateMe("PrivacyPolicy", props?.landId)}
              </Link>
              <Link to="/aboutus">{translateMe("aboutUs", props?.landId)}</Link>
              <Link to="/Mision">{translateMe("ourVision", props?.landId)}</Link>
              <Link to="/" onClick={() => funkcioniIm()}>{translateMe("ourPartners", props?.landId)}</Link>
              {/* <Link to="#">{translateMe("howCanYouHelp", props?.landId)}</Link>
              <Link to="#">{translateMe("upComingEvents", props?.landId)}</Link> */}
            </div>
            <div className="col-lg-2 col-md-2  report">
              <h5 className="">{translateMe("downloadApp", props?.landId)}</h5>
              <div className="Logo_app_download row">
                <div className="google col-12 mt-4 mb-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.harrisia.antidiscrimination"
                  target="_blank"
                  >
                  <img src={app} alt="" />
                </a>
                </div>
                <div className="appstore col-12">
                <a
                  href="https://apps.apple.com/app/raporto-diskriminimin/id1610633355"
                  target="_blank"
                >
                  <img src={android}  alt="" />
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
