import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "../../axios";
import Loader from "../loader/loader";
import { toast } from "react-toastify";
import "../../App.css";

const SafetyOfChildren = (props) => {
  const [safetyData, setSafetyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [textareaContent, setTextareaContent] = useState(""); 
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const translateText = (key) => {
    const translations = {
      "report_concern": {
        al: "Raporto një problem të sigurisë",
        en: "Report a safety concern",
        sr: "Prijavite Bezbednosni Problem",
      },
      "submit_report": {
        al: "Dërgo Raportin",
        en: "Submit Report",
        sr: "Podnesite Izveštaj",
      },
      "submitting": {
        al: "Duke Dërguar...",
        en: "Submitting...",
        sr: "Podnošenje...",
      },
      "report_success": {
        al: "Raporti juaj për sigurinë u dërgua me sukses! Faleminderit për bashkëpunimin tuaj.",
        en: "Your safety report has been successfully submitted! Thank you for your cooperation.",
        sr: "Vaš izveštaj o bezbednosti je uspešno podnet! Hvala na saradnji.",
      },
      "report_error": {
        al: "Gabim gjatë dërgimit të raportit.",
        en: "Error submitting report.",
        sr: "Greška pri podnošenju izveštaja.",
      },
    };
    return translations[key]?.[props.landId] || translations[key]?.en;
  };

  useEffect(() => {
    axios
      .get("/general/safety-of-children/all")
      .then((res) => {
        setSafetyData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching safety data:", error);
        setIsLoading(false);
      });
  }, []);

  const handleTextareaChange = (e) => {
    setTextareaContent(e.target.value);
  };

  const handleSubmit = () => {
    if (!textareaContent.trim()) {
      alert(translateText("report_error"));
      return;
    }

    setIsSubmitting(true);
    axios
      .post("/general/safety-reports", { content: textareaContent })
      .then(() => {
        setTextareaContent("");
        setIsSubmitting(false);
        toast.success(translateText("report_success"), {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch(() => {
        toast.error(translateText("report_error"), {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsSubmitting(false);
      });
  };

  const isButtonDisabled = isSubmitting || textareaContent.trim().length < 5;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="linkpageAbout px-4">
            <div className="row justify-content-center">
              <div className="pt-4">
                {safetyData.map((item, index) => (
                  <div key={index} className="mb-4">
                    <div
                      className="px-2"
                      dangerouslySetInnerHTML={{
                        __html: item[`content_${props.landId}`] || item.content_en,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
            <div className="form-group p-1">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="text-secondary"
              >
                {translateText("report_concern")}
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={textareaContent}
                onChange={handleTextareaChange}
              ></textarea>
              <div className="row">
                <div className="col-12 d-flex justify-content-center justify-content-md-end">
                  <button
                    className="btn LoginBtn my-3 text-white"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                  >
                    {isSubmitting ? translateText("submitting") : translateText("submit_report")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    landId: state.data.langId, 
  };
};

export default connect(mapStateToProps)(SafetyOfChildren);
