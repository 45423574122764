import React, { useEffect, useState, Link } from "react";
import "./DiversityAndEquality.scss";
import Loader from "../loader/loader";
import Pdf_shqip from "./pdf/Shqip.pdf";
import Pdf_Ang from "./pdf/ANG.pdf";
import Pdf_srb from "./pdf/SRB.pdf";
import { connect } from "react-redux";
import axios from "../../axios";

const DiversityAndEquality = (props) => {
  console.log("propsat", props);

  const [documents, setDocuments] = useState([]);
  const [iSloading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  useEffect(() => {
    axios
      .get("admin/documents_publications/all")
      .then((res) => {
        setDocuments(res.data);
        setLoading(false);
      })
      .catch((err) => console.log("error", err));
  }, []);

  console.log("testtest", documents);
  return (
    <>
      {iSloading == true ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="row">
            <h3 className="mb-5 mt-5  border-bottom  border-5 text-center">
              {translateMe("diversityAndEquality", props?.landId)}
            </h3>

            {documents?.map((e) => {
              if (props?.landId == "al") {
                return (
                  <>
                    <div className="col-md-4 mb-5 mt-5 ">
                      <div className="card     card-border ">
                        <div className="card-body p-4 bg-white w-100">
                          <h5
                            className="card-title fs-4  pb-4"
                            dangerouslySetInnerHTML={{ __html: e.title_al }}
                          ></h5>
                          <p className="card-text">
                            <p className="mt-4 h-50">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: e.description_al,
                                }}
                              ></span>
                              <br />
                            </p>
                            {e.pdf_link_al == "" ? (
                              ""
                            ) : (
                              <a
                                href={
                                  process.env.REACT_APP_UPLOADS + e.pdf_link_al
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Lexo më shumë...
                              </a>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="aboutUsLaw">
                        <p
                          dangerouslySetInnerHTML={{ __html: e.content_al }}
                        ></p>
                      </div>
                    </div>
                  </>
                );
              }
              if (props?.landId == "en") {
                return (
                  <>
                    <div className="col-md-4 mb-5 mt-5 ">
                      <div className="card     card-border ">
                        <div className="card-body p-4 bg-white w-100">
                          <h5
                            className="card-title fs-4  pb-4"
                            dangerouslySetInnerHTML={{ __html: e.title_en }}
                          ></h5>
                          <p className="card-text">
                            <p className="mt-4 h-50">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: e.description_en,
                                }}
                              ></span>
                              <br />
                            </p>
                            {e.pdf_link_en == "" ? (
                              ""
                            ) : (
                              <a
                                href={
                                  process.env.REACT_APP_UPLOADS + e.pdf_link_en
                                }
                                target="_blank"
                              >
                                Read more...
                              </a>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="aboutUsLaw">
                        <p
                          dangerouslySetInnerHTML={{ __html: e.content_al }}
                        ></p>
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="col-md-4 mb-5 mt-5 ">
                      <div className="card     card-border ">
                        <div className="card-body p-4 bg-white w-100">
                          <h5
                            className="card-title fs-4  pb-4"
                            dangerouslySetInnerHTML={{ __html: e.title_sr }}
                          ></h5>
                          <p className="card-text">
                            <p className="mt-4 h-50">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: e.description_sr,
                                }}
                              ></span>
                              <br />
                            </p>
                            {e.pdf_link_sr == "" ? (
                              ""
                            ) : (
                              <a
                                href={
                                  process.env.REACT_APP_UPLOADS + e.pdf_link_sr
                                }
                                target="_blank"
                              >
                                Opširnije...
                              </a>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="aboutUsLaw">
                        <p
                          dangerouslySetInnerHTML={{ __html: e.content_al }}
                        ></p>
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiversityAndEquality);
