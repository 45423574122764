import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import "./Aboutus.scss";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../loader/loader";

const About = (propss) => {
  const [lawsHome, setLawsHome] = useState([]);
const [iSloading, setLoading] = useState(true);
  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  useEffect(() => {
    axios
      .get("/general/aboutus")
      .then((res) => {
        setLawsHome(res.data);
        setLoading(false);
      })
      .catch((err) => console.log("error", err));
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  {
    return (
      <>
         {iSloading === true ? (
        <Loader />
      ) : (
        <div className="container">
          
          <h1
            className={
              splitLocation[1] === "About Us"
                ? "active text -center"
                : "text-center"
            }
          >
            {" "}
            {translateMe("mainMenuAboutUs", propss?.landId)}
          </h1>
          <div className="about_us_info">
            {lawsHome?.map((e) => {
              if (propss?.landId == "al") {
                return (
                  <>
                    <p
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: e.content_al.slice(0, 300) + "...",
                      }}
                    ></p>{" "}
                  </>
                );
              }
              if (propss?.landId == "en") {
                return (
                  <>
                    <p
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: e.content_en.slice(0, 300) + "...",
                      }}
                    ></p>{" "}
                  </>
                );
              } else {
                return (
                  <>
                    <p
                      className="text-center"
                      dangerouslySetInnerHTML={{
                        __html: e.content_sr.slice(0, 300) + "...",
                      }}
                    ></p>{" "}
                  </>
                );
              }
            })}

            <div className="mt-5 text-center btn-about ">
              <Link
                to="/aboutus"
                className=" w-100 text-center mt-4 p-2 text-decoration-none"
              >
                {translateMe("showMore", propss?.landId)}
              </Link>
            </div>
          </div>
        </div>
      )}
      </>
    );
    // });
  }
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
