import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./Morenews.scss";
import "../../App.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import axios from "../../axios";
import Loader from "../loader/loader";
const Morenews = (propss) => {
  const [news, setnews] = useState([]);
  const [iSloading, setLoading] = useState(true);
  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  useEffect(() => {
    axios
      .get("/general/news/all")
      .then((res) => {
        // console.log("dataaa", res?.data);
        setnews(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const [pageNumber, setPageNumber] = useState(0);

  const casesPerPage = 6;
  const pagesVisited = pageNumber * casesPerPage;
  if (iSloading == true) {
    return <Loader />;
  }
  const displayCases = news
    .slice(pagesVisited, pagesVisited + casesPerPage)
    .map((el) => {
      if (propss?.landId == "al") {
        return (
          <>
            <div className="col-lg-4 col-md-6 col-12 mb-5">
              <div className="card shadow border-img card-height ">
                <Link to={{ pathname: "/news/" + el.id }}>
                  {el.pic_url ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOADS}` + el.pic_url}
                      className="card-img-top"
                      alt="..."
                    />
                  ) : (
                    <img src="https://image.freepik.com/free-vector/activist-fists-with-different-skin-color-end-racism-text-abstract-anti-racist-strike-other-protest-label-emblem-card-template-blue-background_167715-714.jpg" />
                  )}
                  <div className="card-body text-single ">
                    <h5 className="card-title fw-bold text-dark">{el.name_al}</h5>
                    <p
                      className="card-text text-dark"
                      dangerouslySetInnerHTML={{
                        __html: el.desc_al.slice(0, 180) + "...",
                      }}
                    ></p>
                    <div />
                  </div>
                </Link>
              </div>
            </div>
          </>
        )
      }
      if (propss?.landId == "en") {
        return (
          <>
            <div className="col-lg-4 col-md-6 col-12 mb-5">
              <div className="card shadow border-img card-height ">
                <Link to={{ pathname: "/news/" + el.id }}>
                  {el.pic_url ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOADS}` + el.pic_url}
                      className="card-img-top"
                      alt="..."
                    />
                  ) : (
                    <img src="https://image.freepik.com/free-vector/activist-fists-with-different-skin-color-end-racism-text-abstract-anti-racist-strike-other-protest-label-emblem-card-template-blue-background_167715-714.jpg" />
                  )}
                  <div className="card-body text-single ">
                    <h5 className="card-title fw-bold text-dark">{el.name_en}</h5>
                    <p
                      className="card-text text-dark "
                      dangerouslySetInnerHTML={{
                        __html: el.desc_en.slice(0, 180) + "...",
                      }}
                    ></p>
                    <div />
                  </div>
                </Link>
              </div>
            </div>
          </>
        )
      }
      if (propss?.landId == "sr") {
        return (
          <>
            <div className="col-lg-4 col-md-6 col-12 mb-5">
              <div className="card shadow border-img card-height ">
                <Link to={{ pathname: "/news/" + el.id }}>
                  {el.pic_url ? (
                    <img
                      src={`${process.env.REACT_APP_UPLOADS}` + el.pic_url}
                      className="card-img-top"
                      alt="..."
                    />
                  ) : (
                    <img src="https://image.freepik.com/free-vector/activist-fists-with-different-skin-color-end-racism-text-abstract-anti-racist-strike-other-protest-label-emblem-card-template-blue-background_167715-714.jpg" />
                  )}
                  <div className="card-body text-single ">
                    <h5 className="card-title fw-bold text-dark">{el.name_sr}</h5>
                    <p
                      className="card-text text-dark"
                      dangerouslySetInnerHTML={{
                        __html: el.desc_sr.slice(0, 180) + "...",
                      }}
                    ></p>
                    <div />
                  </div>
                </Link>
              </div>
            </div>
          </>
        )
      }
    });

  const pageCount = Math.ceil(news.length / casesPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div>
      <div className="container pt-5">
        <div className="row ">
          {displayCases}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Morenews);