import React, { useEffect, useState } from "react";
import "./mision.scss";
import axios from "../../axios";
import Loader from "../loader/loader";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

const Mision = (props) => {
  const [mision, setMsion] = useState([]);
  const [iSloading, setLoading] = useState(true);
  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  useEffect(() => {
    axios
      .get("general/mision")
      .then((res) => {
        setMsion(res.data);
        setLoading(false);

        // console.log("misioni", res);
      })
      .catch((err) => console.log("error", err));
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const shareUrl = `https://raportodiskriminimin.org/${pathname}`;

  return (
    <>
      {iSloading == true ? (
        // <div>Loading</div>
        <Loader />
      ) : (
        <div className="container">
          <div className="col-10 offset-1 ">
            <h1 className="text-center mt-5">
              {translateMe("missionAndVision", props?.landId)}
            </h1>
            {mision?.map((e) => {
              if (props?.landId == "al") {
                return (
                  <>
                    <div className="aboutUsLaw">
                      <p
                        className=""
                        dangerouslySetInnerHTML={{ __html: e.content_al }}
                      ></p>
                    </div>
                  </>
                );
              }
              if (props?.landId == "en") {
                return (
                  <>
                    <div className="aboutUsLaw">
                      <p
                        className=""
                        dangerouslySetInnerHTML={{ __html: e.content_en }}
                      ></p>
                    </div>
                    ;
                  </>
                );
              } else {
                return (
                  <>
                    <div className="aboutUsLaw">
                      <p
                        className=""
                        dangerouslySetInnerHTML={{ __html: e.content_sr }}
                      ></p>
                    </div>
                  </>
                );
              }
            })}
          </div>
          <div className="allIcons">
            <div className="smallIcons">
              <FacebookShareButton url={shareUrl} quote={"test"}>
                <FacebookIcon size={40} round />
              </FacebookShareButton>
            </div>
            <div className="smallIcons">
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={40} round />
              </TwitterShareButton>
            </div>

            <div className="smallIcons">
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mision);
